import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import FileLink from "../SingleInvoiceTable/FileLink";
import { Box, Snackbar } from "@mui/material";
import { deleteBulkInvoice, getSummaryReport } from "utils/api/invoice";
import Button from "components/common/Button";
import { useState } from "react";
import PopUp from "components/common/PopUp";
import { set } from "lodash";
import history from "router/history";
import { route } from "constants/routes";
import { useQueryClient } from "react-query";


const PostalInvoices = ({ invoices }: { invoices: any }) => {
    const postals = invoices.filter(invoice => invoice.deliveryType === "postal");

    if (postals.length) return <FileLink fileKey={postals[0]?.fileId} label={`Print (${postals.length})`} />;

    return <Box>No postal found</Box>;
}


const SummaryReport = ({ bulkInvoiceId }: { bulkInvoiceId: string }) => {
    const [isLoading, setLoading] = useState(false);

    const openLink = async () => {
        try {
            setLoading(true);
            const response = await getSummaryReport(bulkInvoiceId);
            const fileKey = response.data;
            window.open(`${process.env.REACT_APP_USER_BASE_URL}/files/${fileKey}`, '_blank').focus();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button disabled={isLoading} label={isLoading ? "Generating report..." : "Download Report"} size="small" onClick={openLink} />
    );
};

const DeleteAction = ({ id }: { id: string }) => {
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [notiVisible, setNotiVisible] = useState(false);
    const queryClient = useQueryClient()

    const handleDelete = async () => {
        setLoading(true);
        try {
            await deleteBulkInvoice(id);
            setNotiVisible(true);
            queryClient.invalidateQueries("getBulkInvoices");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setConfirmVisible(false);
        }
    }

    return <Box>
        {confirmVisible && <PopUp
            heading="Delete Bulk Invoice"
            open={confirmVisible}
            handleClose={() => setConfirmVisible(false)}
            actionLeft={{
                disabled: isLoading,
            }}
            actionRight={{
                label: isLoading ? "Processing..." : "Delete",
                disabled: isLoading,
            }}
            handleRightBtnClick={handleDelete}>
            <Box>Are you sure you want to delete this bulk invoice, all of single invoices belong to this bulk invoice will be deleted as well?</Box>
        </PopUp>}
        <Box
            width={20}
            height={20}
            component="img"
            src="/icons/del-bin.svg"
            alt="delete bulk invoice"
            onClick={() => setConfirmVisible(true)}
            tabIndex={0}
        />
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notiVisible}
            onClose={() => setNotiVisible(false)}
            message="Bulk Invoice has been deleted successfully"
            key={"bulk-invoice-deleted"}
        />
    </Box>
}

export const columns: GridColDef[] = [
    {
        field: "created_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { created_at } }: GridCellParams) => new Date(created_at).toDateString()

    },
    {
        field: "user",
        headerName: "Uploaded By",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { user } }: GridCellParams) => user.firstName + " " + user.lastName
    },
    {
        field: "fileId",
        headerName: "File",
        sortable: false,
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { fileId } }: GridCellParams) => <FileLink fileKey={fileId} label={fileId} />

    },
    {
        field: "invoices",
        headerName: "Total Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => invoices.length
    },
    {
        field: "id",
        headerName: "Postal Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => <PostalInvoices invoices={invoices} />
    },
    {
        field: "report",
        headerName: "Report",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { id } }: GridCellParams) => <SummaryReport bulkInvoiceId={id} />
    },
    {
        field: "action",
        headerName: "Actions",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { id } }: GridCellParams) => <DeleteAction id={id} />
    }
];
