import { useState } from "react"
import { useMutation } from "react-query";
import { bulkInvitePrimaryMembers, InviteInfo } from "utils/api/invite";

interface Data {
    errors: string[];
}

interface Params {
    careHomeId: string;
    residents: InviteInfo[];
}

const useActions = (onSuccess?: () => any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState<Data | null>(null);

    const { mutate: inviteMembers } = useMutation(
        ["bulkInvitePrimaryMembers"],
        ({ careHomeId, residents }: Params) => {
            return bulkInvitePrimaryMembers(careHomeId, residents);
        },
        {
            onMutate: () => {
                setData(null);
                setError('');
                setLoading(true);
                console.log("mutate");
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                setData(data.data?.data);
                onSuccess && onSuccess();
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
                console.log("error", err);
            },
        },
    );


    return { inviteMembers, loading, error, data };
}

export default useActions;