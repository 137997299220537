import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Divider } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import Info from "components/common/Info";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import SearchBar from "components/common/SearchField";
import Toggle from "components/common/Toggle";
import { errorModal } from "constants/dummyData";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import {
  setCareHomeTimeZone,
  setGlobalInfoData,
  setMrModalView,
  setResidentsRowData,
} from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { InfoData, ManageResidentData } from "types/common";
import {
  addMember,
  getAllResidents,
  getCareHomeByPccLocal,
  getCareHomeInfo,
  markResidentNotNew,
  updateResidentStatus,
  fetchResidentMember,
} from "utils/api/user";
import {
  getMaskedDate,
  getInfoData,
  residentDataTransformer,
} from "utils/helper";
import { ResidentDetails, defaultValue } from "../AddResident";
import ResidentDetail from "../AddResident/Resident/Forms/ResidentDetail";
import AddResident from "./AddResident";
import Contacts from "./Contacts";
import InviteResident from "./InviteResident";
import styles from "./styles";
import { Link } from "react-router-dom";

type Modal = "activate" | "invite" | "unableToFetch" | undefined;

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type ToggleResident = {
  props: {
    description: string;
    note?: string;
  };
} & ModalData;

export type InfoModal = {
  data: {
    name: string;
    email: string;
  }[];
} & ModalData;

export type ResidentDetailsProps = {
  primaryMembers: {
    firstName: string;
    lastName: string;
    email?: string;
  }[];

  familyMembers: {
    firstName: string;
    lastName: string;
    email?: string;
  }[];

  show: boolean;
};

const ResidentsDefault = () => {
  const {
    pccInfo: { pccId },
    user: { globalInfoData, residentsRowData, careHomeTimeZone },
  } = useAppSelector((state) => state);

  const [detailView, setView] = useState<boolean>(false);
  const [userData, setUserData] = useState();
  const [openModal, setOpenModal] = useState<Modal>(undefined);
  const [rowData, setRowData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [residentId, setResidentId] = useState("");
  const [residentData, setResidentData] = useState<any>({});
  const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
  const [fetchContacts, setFetchContacts] = useState<boolean>(false);

  const initialFamilyDetails = {
    primaryMembers: [],
    familyMembers: [],
    show: false,
  };

  const [showResidentDetail, setShowResidentDetail] =
    useState<ResidentDetailsProps>(initialFamilyDetails);

  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    setValue,
    reset,
    setError,
    getValues,
    clearErrors,
  } = useForm<ResidentDetails>({
    mode: "onChange",
    defaultValues: {
      residents: defaultValue,
    },
  });

  const { data: careHomeData } = useQuery(
    ["getCareHome", pccId],
    () => getCareHomeByPccLocal(pccId),
    {
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: allResidents, refetch: refetchAllResidents } = useQuery(
    ["allResidents", page, search, pccId],
    () => getAllResidents({ pccId, page, search }),
    {
      onSuccess: (res) => {
        if (detailView) {
          const data = {
            ...residentsRowData,
            lastAction: res.data?.data?.find(
              (resident) => resident?.gerryId === residentsRowData?.gerryId,
            )?.lastAction,
          };
          dispatch(setResidentsRowData(data));

          handleDetailView(data);
        }
        setTotalData(res?.data?.metadata?.total);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: residentInfo, refetch: refetchResidentInfo } = useQuery(
    ["residentInfo", pccId],
    () => getCareHomeInfo(pccId),
    {
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate, isLoading } = useMutation(
    "statusToggle",
    (data: { pccId: string; id: string; isActive: boolean }) =>
      updateResidentStatus(data),
    {
      onSuccess: () => {
        refetchResidentInfo();
        refetchAllResidents();
        handleClose();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate: markResNotNew } = useMutation(
    "markNotNew",
    (id: string) => markResidentNotNew(id),
    {
      onSuccess: () => { },
      onError: (err) => { },
      retry: 1,
    },
  );

  const { mutate: addMemberMutate } = useMutation(
    "addAMember",
    (data: {
      residentId: string;
      isPrimary: boolean;
      firstName: string;
      lastName: string;
      email: string;
    }) => addMember(data),
    {
      onSuccess: () => { },
      onError: (err) => {
        if (err["response"].data.errorCode === "MYG_ERR_C006") {
          setError("residents.familyMembers.0.firstName", {
            message: "RESIDENT ALREADY EXISTS",
          });
        }
      },
    },
  );

  const { mutate: fetchResidentMutate } = useMutation(
    "addAMember",
    (residentId: string) => fetchResidentMember(residentId),
    {
      onSuccess: () => {
        setFetchContacts(true);
      },
      onError: (err) => {
        if (err["response"]?.data?.statusCode !== 429) {
          alert("Not enough quota limit for this request");
        }
      },
    },
  );

  useEffect(() => {
    refetchAllResidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      residents: {
        firstName: residentData?.firstName || "ff",
        lastName: residentData?.lastName || "",
        gender: residentData?.gender?.toLowerCase() || "",
        dob: getMaskedDate(residentData?.dateOfBirth) || "",
        /*  new Date(residentData?.dateOfBirth).toLocaleString("en-US", {
            timeZone: "GMT",
            year: "numeric",
            month: "long",
            day: "numeric",
          }) || "", */
        primaryContact: {
          email: showResidentDetail?.primaryMembers?.[0]?.email || "",
          firstName: showResidentDetail?.primaryMembers?.[0]?.firstName || "",
          lastName: showResidentDetail?.primaryMembers?.[0]?.lastName || "",
        },
      },
    });
  }, [
    reset,
    residentData?.dateOfBirth,
    residentData?.firstName,
    residentData?.gender,
    residentData?.lastName,
    showResidentDetail?.primaryMembers,
    showResidentDetail.show,
  ]);

  const careHomeInfo = careHomeData?.data?.data;

  const address = [
    careHomeInfo?.address?.addressLine1,
    careHomeInfo?.address?.city,
    careHomeInfo?.address?.province,
    careHomeInfo?.address?.country,
  ]
    ?.filter((item) => item && item !== "")
    ?.join(", ");

  const source = globalInfoData?.source;
  const infoData: InfoData = useMemo(
    () => ({
      heading: careHomeInfo?.name,
      content: [
        {
          label: "",
          value: `${careHomeInfo?.firstName ?? ""} ${careHomeInfo?.lastName ?? ""
            }${careHomeInfo?.mainPhoneNumber?.length > 3
              ? ", " + careHomeInfo?.mainPhoneNumber
              : ""
            }`,
        },
        {
          label: "Address:",
          value: address,
        },
        {
          label: "Admin Emails:",
          value: "View All",
          action: true,
        },
        {
          label: "Residents:",
          value: `${residentInfo?.data?.data?.totalResidents || 0
            }   |   Active:  ${residentInfo?.data?.data?.activeResidentCount || 0
            }   |   Inactive:  ${residentInfo?.data?.data?.inActiveResidentCount || 0
            }`,
        },
      ],
    }),
    [
      address,
      careHomeInfo?.firstName,
      careHomeInfo?.lastName,
      careHomeInfo?.mainPhoneNumber,
      careHomeInfo?.name,
      residentInfo?.data?.data?.activeResidentCount,
      residentInfo?.data?.data?.inActiveResidentCount,
      residentInfo?.data?.data?.totalResidents,
    ],
  );

  useEffect(() => {
    if (!!(residentInfo && careHomeInfo)) {
      setInfoData(infoData);
      dispatch(
        setGlobalInfoData({
          ...infoData,
          isInvited: careHomeInfo?.isInvited,
          source: careHomeInfo?.source,
        }),
      );
      dispatch(setCareHomeTimeZone(careHomeInfo?.timeZone));
    }
  }, [careHomeInfo, dispatch, infoData, residentInfo]);

  const mrViewAllModal: InfoModal = useMemo(
    () => ({
      heading: "Admin Care Home",
      data: careHomeInfo?.adminEmails.map((data) => ({
        ...data,
        name: `${data?.firstName || ""} ${data?.lastName || "-"}`,
      })),
      actionLeft: {
        hidden: true,
      },
      actionRight: {
        hidden: true,
      },
    }),
    [careHomeInfo?.adminEmails],
  );

  useEffect(() => {
    mrViewAllModal && dispatch(setMrModalView(mrViewAllModal));
  }, [dispatch, mrViewAllModal]);

  const [info, setInfoData] = useState<InfoData>(infoData);

  const getSearchText = (text: string) => {
    const txt = text.trim().toLowerCase();
    setSearch(txt);
  };

  const handleDetailView = (data) => {
    setView(true);
    setUserData(data);
    setInfoData(getInfoData({ data, careHomeTimeZone }));
  };

  useEffect(() => {
    if (fetchContacts) {
      handleDetailView(residentData);
    }

    return setFetchContacts(false);
  }, [fetchContacts, handleDetailView, residentData]);

  const getDescWithName = (name, isActive) => {
    return isActive
      ? `Do you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };

  const deactivateResident: ToggleResident = {
    heading: "Deactivate Resident",
    props: {
      description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Deactivate",
      color: "error",
      actionCustomStyles: {
        button: {
          backgroundColor: "#c0291d",
        },
      },
    },
  };

  const activateResident: ToggleResident = {
    heading: "Activate Resident",
    props: {
      description: "Do you want to activate ‘Asus Nursing Home’?",
      note: "",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Activate",
    },
  };

  const handleToggle = (data: ManageResidentData) => {
    setRowData(data);
    data.status
      ? setModalData({
        ...deactivateResident,
        props: {
          ...deactivateResident.props,
          description: getDescWithName(data.residentName, data.status),
        },
      })
      : setModalData({
        ...activateResident,
        props: {
          ...activateResident.props,
          description: getDescWithName(data.residentName, data.status),
        },
      });
    setOpenModal("activate");
  };

  const handleRightBtnClick = () => {
    mutate({ pccId, ...rowData });
  };

  const [modalData, setModalData] = useState(deactivateResident);

  const handleClose = () => {
    setOpenModal(undefined);
  };

  const renderResidentName = (params: GridCellParams) => {
    return (
      <Box
        key={`${params.row.id}-${params.row.id}`}
        sx={styles.tableLink}
        onClick={() => {
          setResidentData(params.row);
          dispatch(setResidentsRowData(params.row));
          setResidentId(params.row.id);
          fetchResidentMutate(params.row.id);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setResidentData(params.row);
            dispatch(setResidentsRowData(params.row));
            setResidentId(params.row.id);
            fetchResidentMutate(params.row.id);
          }
        }}
        tabIndex={0}
      >
        <Box sx={styles.nameWrapper}>{params.row.residentName}</Box>
      </Box>
    );
  };

  const renderStatus = (params: GridCellParams) => {
    return (
      <Box sx={styles.actionIcon}>
        <Toggle
          value={params.row.status}
          onChange={() => handleToggle(params.row)}
        />
      </Box>
    );
  };

  const renderInvite = (params: GridCellParams) => {
    return (
      <Box sx={styles.nameWrapper}>
        <Box
          sx={
            params.row.status && !!params?.row?.primaryMember?.firstName
              ? styles.tableLink
              : styles.tableLinkDisabled
          }
          onClick={() => params.row.isNew && markResNotNew(params.row.id)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              params.row.isNew && markResNotNew(params.row.id);
            }
          }}
        >
          <InviteResident
            params={params.row}
            type="resident"
            refetch={refetchAllResidents}
          />
        </Box>
        {params.row.isNew && (
          <Box>
            <Box sx={styles.newWrapper}>
              <Box sx={styles.isNewDot} />
              New
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const renderPrimary = (params: GridCellParams) => {
    return (
      <Box sx={styles.primaryContactWrapper}>
        <Box sx={styles.nameWrapper}>{params.row.primaryContact}</Box>
        {params.row.isPrimaryChanged && (
          <Box
            sx={styles.imgStyles}
            component="img"
            src="/icons/primary-change.png"
            alt="change-primary"
          />
        )}
      </Box>
    );
  };

  const residentColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "residentName",
      headerName: "Resident Name",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderResidentName,
    },
    {
      field: "gerryId",
      headerName: "Gerry ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderPrimary,
    },
    {
      field: "invite",
      headerName: "Invite",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderInvite,
    },
  ];

  const callApiForFamilyMember = (data) =>
    data.residents?.familyMembers?.forEach(async (item, idx) => {
      if (!item?.id) {
        const dataObj = {
          residentId: residentId,
          isPrimary: false,
          firstName: item?.firstName,
          lastName: item?.lastName,
          email: item.email,
        };
        if (dataObj?.email === "") {
          delete dataObj.email;
        }
        addMemberMutate(dataObj, {
          onSuccess: () => {
            setShowResidentDetail(initialFamilyDetails);
          },
          onError: (err) => {
            setError(`residents.familyMembers.${idx}.firstName`, {
              message: err["response"].data?.message?.[0],
            });
          },
        });
      }
    });

  const onSubmit = (data) => {
    callApiForFamilyMember(data);
    setRefetchFlag(true);
  };
  const history = useHistory();

  const checkIfEmptyName = () => {
    const familyLength = watch("residents.familyMembers")?.length;
    for (let i = 0; i < familyLength; i++) {
      if (
        !getValues(`residents.familyMembers.${i}.firstName`) ||
        !getValues(`residents.familyMembers.${i}.lastName`)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleRightBtnClick}
          loading={isLoading}
        >
          <SimplePopUp
            description={modalData.props.description}
            note={modalData.props.note}
          />
        </PopUp>
      )}

      {showResidentDetail.show ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="familyForm">
            <ResidentDetail
              control={control}
              errors={errors}
              setValue={setValue}
              masterLabel="Resident's details"
              showButton={false}
              watch={watch}
              setError={setError}
              formForFamily={true}
              clearErrors={clearErrors}
              customStyles={{
                customFormWrapper: styles.customFormWrapper,
                customEmailWrapper: styles.customEmailWrapper,
                customFieldWrapper: styles.customFieldWrapper,
              }}
              getValues={getValues}
            />

            <Box sx={{ mt: "20px" }}>
              <Box sx={styles.btnWrapper}>
                <Button
                  type="submit"
                  sx={{
                    fontSize: "14px",
                    height: "40px",
                    minWidth: "120px",
                  }}
                  variant="outlined"
                  onClick={() => setShowResidentDetail(initialFamilyDetails)}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  sx={styles.saveBtnStyles}
                  variant="contained"
                  disabled={checkIfEmptyName() ? true : isDirty ? false : true}
                >
                  Save
                </Button>
              </Box>
              <Divider sx={styles.dividerStylesV2} color="#82B7F3" />
            </Box>
          </form>
        </>
      ) : (
        <>
          <Box sx={styles.backBtnWrapper}>
            <Box sx={styles.infoWrappers}>
              <Info
                content={info.content}
                heading={info.heading}
                mrViewAllModal={mrViewAllModal}
                customStyles={{
                  active: { cursor: "not-allowed" },
                  inActive: { cursor: "not-allowed" },
                }}
              />
            </Box>

            <Box sx={styles.backWrapper}>
              <Box
                sx={styles.backStyles}
                onClick={() => {
                  detailView && setInfoData(infoData);
                  detailView ? setView(false) : history.push("/");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    detailView && setInfoData(infoData);
                    detailView ? setView(false) : history.push("/");
                  }
                }}
                tabIndex={0}
              >
                <ArrowBackIosIcon sx={styles.backArrow} />
                <Box>Back</Box>
              </Box>
            </Box>
          </Box>

          <Box sx={styles.tableWrapper}>
            {detailView ? (
              <>
                <Box sx={styles.formWrapper}>
                  <Contacts
                    contact={userData}
                    refetchAllResidents={refetchAllResidents}
                    residentId={residentId}
                    setShowResidentDetail={setShowResidentDetail}
                    refetchFlag={refetchFlag}
                    setRefetchFlag={setRefetchFlag}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={styles.searchBar}>
                    <SearchBar getText={getSearchText} />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Link to="/bulk-invite" style={{ textDecoration: "none" }}>
                      <Box sx={styles.bulkInvite}>
                        Bulk Invite
                      </Box>
                    </Link>
                    {source === "gerry" && <AddResident />}
                  </Box>
                </Box>
                <DataGrid
                  rows={residentDataTransformer(allResidents?.data?.data) || []}
                  columns={residentColumn}
                  disableSelectionOnClick
                  onPageChange={(pageNo) => setPage(pageNo + 1)}
                  rowsPerPageOptions={[10]}
                  pageSize={10}
                  rowCount={totalData}
                  loading={false}
                  componentsProps={{
                    toolbar: { showQuickFilter: true },
                  }}
                  emptyTable="No residents added."
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ResidentsDefault;
