import BulkInvite from "components/feature/BulkInvite";

const BulkInvitePage = () => {
  return (
    <>
      <BulkInvite />
    </>
  );
};

export default BulkInvitePage;
