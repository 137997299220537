import dataProvider from "dataProvider";

let fetcher = dataProvider("adminInstance");

export interface InviteInfo {
    residentId: string;
    email: string;
}

export const bulkInvitePrimaryMembers = async (careHomeId: string, residents: InviteInfo[]) => {

    return await fetcher.post(
        `/invite/member/bulk?careHomeId=${careHomeId}`,
        { residents },
    );
};

export const getPendingInviteResidents = async (careHomeId: string) => {

    return await fetcher.get(
        `/invite/member/bulk/pending?careHomeId=${careHomeId}`,
    );
};

export const getInviteJobs = async (careHomeId: string) => {

    return await fetcher.get(
        `/invite/member/bulk/jobs?careHomeId=${careHomeId}`,
    );
};

