import { Box, Input, Snackbar } from "@mui/material";
import Button from "components/common/Button";
import DataGrid from "components/common/DataGrid";
import PopUp from "components/common/PopUp";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { columns } from "./columns";
import CareHomeInfoCard from "components/common/CareHomeInfoCard";
import styles from "./styles";
import useActions from "./actions";
import { getPendingInviteResidents } from "utils/api/invite";


const BulkInvite = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [notiVisible, setNotiVisible] = useState(false);
    const [emailData, setEmailData] = useState<{ [residentId: string]: string }>({});
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const {
        pccId
    } = useAppSelector((state) => state.pccInfo);

    const { data: allResidents, refetch: refetchAllResidents } = useQuery(
        ["allResidents", page, pccId],
        () => getPendingInviteResidents(pccId),
        {
            onSuccess: (res) => {
                setTotalData(res?.data?.metadata?.total);
            },
            retry: 1,
        },
    );

    const onDone = () => {
        setEmailModalOpen(false);
        setModalOpen(false);
        setNotiVisible(true);
        setSelected([]);
        refetchAllResidents();
    }

    const { inviteMembers, loading } = useActions(onDone);

    const eligibleResidents = React.useMemo(() => allResidents?.data?.data || [], [allResidents]);

    //
    const missingEmailResidents = React.useMemo(() =>
        eligibleResidents.filter(item => selected.includes(item.id)).filter((resident) => !resident.user.email), [eligibleResidents, selected]);

    const handleInvite = () => {
        if (missingEmailResidents.length > 0) {
            setModalOpen(false);
            setEmailModalOpen(true);
        } else {
            inviteMembers({
                careHomeId: pccId, residents: selected.map(id => ({
                    residentId: id,
                    email: eligibleResidents.find(resident => resident.id === id)?.user.email
                }))
            });
        }
    }

    const confirmInvite = () => {
        inviteMembers({
            careHomeId: pccId, residents: selected.map(id => ({
                residentId: id,
                email: emailData[id] || eligibleResidents.find(resident => resident.id === id)?.user.email
            }))
        });
    }



    return <Box sx={styles.wrapper}>
        <CareHomeInfoCard showToggle={false} />
        <Box mt={2}>
            <Box fontSize={22} fontWeight="bold">Bulk Invite</Box>
            <Box fontSize={12}>Invite multiple residents at once</Box>
        </Box>
        <Box>
            {modalOpen && <PopUp
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                heading="Bulk Invite"
                actionLeft={{
                    hidden: true,
                }}
                actionRight={{
                    label: loading ? "Processing..." : "Okay",
                }}
                handleRightBtnClick={handleInvite}
            >
                <Box>Are you sure you want to send invite to members of {selected.length} residents?</Box>
            </PopUp>}
        </Box>
        {emailModalOpen && <PopUp
            open={emailModalOpen}
            handleClose={() => setEmailModalOpen(false)}
            heading="Update primary member email"
            actionLeft={{
                hidden: true,
            }}
            actionRight={{
                label: loading ? "Processing..." : "Continue",
                disabled: missingEmailResidents.some(resident => !emailData[resident.id] || emailData[resident.id].trim() === ""),
            }}
            handleRightBtnClick={confirmInvite}
        >
            <Box maxHeight="calc(100vh - 300px)" overflow="auto">
                <Box fontSize={14} fontWeight="bold" mb={2}>
                    Following residents do not have email of primary contact to sent invite. Please enter the emails to continue
                </Box>
                {missingEmailResidents.map((resident) =>
                    <Box key={resident.id} display="flex" flexDirection="row" alignItems="center" mb={1} justifyContent="space-between">
                        <Box>{resident.firstName + ' ' + resident.lastName}</Box>
                        <Box>
                            <Input
                                value={emailData[resident.id]}
                                placeholder="Enter email"
                                onChange={event => setEmailData({ ...emailData, [resident.id]: event.target.value })} />
                        </Box>
                    </Box>)}
            </Box>

        </PopUp>}
        <DataGrid
            rows={eligibleResidents}
            columns={columns}
            disableSelectionOnClick
            onPageChange={(pageNo) => setPage(pageNo + 1)}
            rowsPerPageOptions={[10]}
            pageSize={10}
            rowCount={totalData}
            checkboxSelection
            selectionModel={selected}
            loading={false}
            onSelectionModelChange={(newSelection: string[]) => {
                setSelected(newSelection);
            }}
            componentsProps={{
                toolbar: { showQuickFilter: true },
            }}
            emptyTable="No residents added."
        />
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notiVisible}
            onClose={() => setNotiVisible(false)}
            message="Emails are sent successfully"
            key={"bulk-email-sent"}
        />
        <Box>
            <Button size="small"
                style={{ marginRight: 10 }}
                label={`Invite all residents (${eligibleResidents.length})`}
                onClick={() => {
                    setSelected(eligibleResidents.map(resident => resident.id));
                    setModalOpen(true);
                }} />
            {selected.length > 0 && <Button size="small" label={`Invite for ${selected.length} selected residents`} onClick={() => setModalOpen(true)} />}
        </Box>
    </Box>
}

export default BulkInvite;