import { Box } from "@mui/material";
import { AxiosResponse } from "axios";
import PopUp, { Action } from "components/common/PopUp";
import { SimpleInput, SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import { ResidentInvite } from "types/api";
import { sendInviteResident } from "utils/api/user";
import regex from "utils/regex";
import styles from "./styles";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props?: any;
};

type InviteSent = {
  props: {
    description: string;
  };
} & ModalData;

export type InviteResidentFormData = {
  email: string;
};

export type InviteResidentProps = {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<AxiosResponse<any>, unknown>>;
};

type Modal = "enterEmail" | "invite" | undefined;

const InvitationManager = ({
  params,
  type,
  setModal,
  setModalData,
  emailPrimary,
  heading,
}: any) => {
  const inviteMember = {
    heading,
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Send Invite",
      type: "submit",
    },
  };

  const hasInvited = (data) => {
    if (!data) {
      return "Send Invite";
    }
    if (data?.onBoarded === false) {
      if (data?.invitationToken === null) {
        return "Send Invite";
      } else {
        return "Resend Invite";
      }
    } else {
      return "Resend Invite";
    }
  };

  switch (type) {
    case "resident": {
      const isOnBoarded = params.primaryMember?.[0]?.onBoarded;
      const isInviteEnable = params.status && !!params?.primaryMember?.[0]?.firstName && !isOnBoarded;
      return (
        <Box
          sx={isInviteEnable ? styles.tableLink : styles.tableLinkDisabled}
          onClick={() => {
            if (isInviteEnable) {
              setModal("enterEmail");
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (isInviteEnable) {
                setModal("enterEmail");
              }
            }
          }}
          tabIndex={0}
        >
          {hasInvited(params?.primaryMember?.[0])}
        </Box>
      );
    }
    case "primaryMember": {
      const isOnBoarded = params.onBoarded;
      return (
        <Box
          sx={
            params.status && !isOnBoarded
              ? styles.tableLink
              : styles.tableLinkDisabled
          }
          onClick={() => {
            if (params.status && !isOnBoarded) {
              setModal("enterEmail");
              setModalData(!isOnBoarded ? emailPrimary : inviteMember);
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (params.status && !isOnBoarded) {
                setModal("enterEmail");
                setModalData(!isOnBoarded ? emailPrimary : inviteMember);
              }
            }
          }}
        >
          {hasInvited(params)}
        </Box>
      );
    }
    case "familyMember": {
      const isOnBoarded = params.onBoarded;
      return (
        <Box
          sx={
            params.status && !isOnBoarded
              ? styles.tableLink
              : styles.tableLinkDisabled
          }
          onClick={() => {
            if (params.status && !isOnBoarded) {
              setModal("enterEmail");
              setModalData(inviteMember);
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (params.status && !isOnBoarded) {
                setModal("enterEmail");
                setModalData(inviteMember);
              }
            }
          }}
        >
          {hasInvited(params)}
        </Box>
      );
    }
    default: {
      break;
    }
  }
};

const InviteResident = ({
  params,
  type = "primaryMember",
  heading = "Invite Primary Contact",
  secondaryHeading = "Invite Primary Contact",
  label = "Enter the Email Address to invite Primary Contact of this resident.",
  refetchFamily,
  refetchPrimary,
  refetch,
}: any) => {
  const { control, getValues, trigger, watch, formState, reset, setError } =
    useForm<InviteResidentFormData>({
      mode: "onTouched",
      defaultValues: { email: "" },
    });

  const { errors } = formState;
  const [isDisabled] = useBtnDisableToggler({ formState, watch });
  const [openModal, setModal] = useState<Modal>();
  const [load, setLoad] = useState(false);

  const { mutate: inviteResident } = useMutation(
    "inviteResident",
    (data: ResidentInvite) => sendInviteResident(data),
    {
      onSuccess: () => {
        setLoad(false);
        setModal("invite");
        setModalData(inviteSent);
        switch (type) {
          case "resident": {
            refetch();
            break;
          }
          case "familyMember": {
            refetchFamily();
            break;
          }
          case "primaryMember": {
            refetchPrimary();
            break;
          }
        }
      },
      onError: (err) => {
        setLoad(false);
        setError("email", { message: err["response"].data.message });
      },
      retry: 1,
    },
  );

  const handleClose = () => {
    setModal(undefined);
    setModalData(emailPrimary);
    reset();
  };

  const inviteSent: InviteSent = {
    heading: "Invite Sent",
    props: {
      description: "An email invite has been sent.",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Okay",
      onClick: () => handleClose(),
    },
  };

  const emailPrimary: ModalData = {
    heading,
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Send Invite",
      type: "submit",
    },
  };

  const [modalData, setModalData] = useState(emailPrimary);

  const handleRightBtnClick = () => {
    switch (openModal) {
      case "enterEmail": {
        trigger();
        setLoad(true);
        inviteResident({
          ...getValues(),
          contactId: params?.contactId || params?.primaryMember?.[0]?.contactId,
          residentId: params?.id,
          type: type === "resident" ? "primaryMember" : type,
        });
        break;
      }
      case "invite": {
        handleClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box>
      <InvitationManager
        params={params}
        setModal={setModal}
        setModalData={setModalData}
        type={type}
        emailPrimary={emailPrimary}
        heading={secondaryHeading}
      />
      <PopUp
        open={!!openModal}
        handleClose={handleClose}
        heading={modalData.heading}
        actionLeft={modalData.actionLeft}
        actionRight={{
          ...modalData.actionRight,
          disabled: isDisabled,
        }}
        handleRightBtnClick={handleRightBtnClick}
        loading={load}
      >
        {openModal === "enterEmail" && (
          <SimpleInput
            name="email"
            label={
              type !== "familyMember"
                ? "Please enter primary contact email address."
                : "Please enter family member email address"
            }
            control={control}
            placeholder="example@gmail.com"
            errors={errors}
            autoFocus={false}
            rules={{
              required: {
                value: true,
                message: errorMessage.required,
              },
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
            }}
          />
        )}
        {openModal === "invite" && (
          <SimplePopUp
            description={inviteSent.props.description}
            note={inviteSent.props.note}
          />
        )}
      </PopUp>
    </Box>
  );
};

export default InviteResident;
