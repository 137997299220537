import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "5.5px",
      border: "none",
      borderRadius: "5px",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },

  familyMemberTableWrapper: {
    height: "calc(100vh - 275px)",
  },

  primaryContactWrapper: {
    display: "flex",
    gap: "8px",
    overflow: "hidden",
  },
  customEmailWrapper: {
    "& .MuiInputBase-root": {
      height: "40px",
    },
  },
  imgStyles: {
    width: "21px",
    height: "19px",
  },

  nameWrapper: {
    whiteSpace: "nowrap",
    maxWidth: "180px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

export default styles;
