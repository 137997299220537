import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid"
import styles from "./styles";



const renderResidentName = ({ row }: GridCellParams) => {
  return (
    <Box
      key={`${row.id}-${row.id}`}
      tabIndex={0}
    >
      <Box sx={styles.nameWrapper}>{`${row?.firstName} ${row?.lastName}`}</Box>
    </Box>
  );
};


const renderPrimary = ({ row: { user } }: GridCellParams) => {
  return (
    <Box sx={styles.primaryContactWrapper}>
      <Box sx={styles.nameWrapper}>{user?.firstName + ' ' + user?.lastName}</Box>
    </Box>
  );
};


export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "S.No",
    sortable: false,
    flex: 1,
    hide: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "residentName",
    headerName: "Resident Name",
    sortable: true,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: renderResidentName,
  },
  {
    field: "gerryId",
    headerName: "Gerry ID",
    sortable: true,
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "primaryContact",
    headerName: "Primary Contact",
    sortable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: renderPrimary,
  },
  {
    field: "primaryMember",
    headerName: "POA Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: { user } }: GridCellParams) => {
      return user?.email || "Empty";
    }
  },
];