import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "11px",
      border: "none",
      borderRadius: "5px",
    },
    "& .MuiInputBase-root": {
      height: "28px",
    },
    "& .familyForm": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },

  familyMemberTableWrapper: {
    height: "calc(100vh - 275px)",
  },

  tableLink: {
    color: "primary.main",
    cursor: "pointer",
    textDecoration: "underline",
    overflow: "hidden",
  },

  tableLinkDisabled: {
    color: "custom.background.darkGray",
    cursor: "not-allowed",
    fontWeight: 300,
    textDecoration: "underline",
  },

  status: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  },

  del: {
    cursor: "pointer",
  },

  table: {
    borderBottom: "none",
  },

  searchBar: {
    width: "50%",
    marginBottom: "15px",
  },

  viewAllWrapper: {
    height: "216px",
    overflowY: "auto",
    background: "white",
    color: "custom.text.secondary",
    paddingLeft: "8px",
  },

  viewItemHead: {
    flexBasis: "40%",
    fontWeight: "bold",
    fontSize: "15px",
  },

  viewAll: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    lineHeight: "48px",
    fontSize: "14px",
    fontWeight: "500",
  },

  viewItem: {
    flexBasis: "40%",
  },

  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
  },

  formWrapper: {
    position: "relative",
  },

  backWrapper: {
    position: "relative",
  },

  backStyles: {
    width: "50px",
    display: "flex",
    fontSize: "15px",
    alignItems: "center",
    cursor: "pointer",
    color: "custom.background.dropdownCta",
    top: "10px",
    left: "10px",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "50px",
      background: "#056EE6",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },

  backArrow: {
    marginRight: "10px",
    cursor: "pointer",
  },

  nameWrapper: {
    whiteSpace: "nowrap",
    maxWidth: "180px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  isNewDot: {
    width: "3px",
    height: "3px",
    borderRadius: "100px",
    backgroundColor: "#2F6CDE",
  },

  newWrapper: {
    fontSize: "9px",
    color: "#2F6CDE",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    gap: "1px",
    position: "absolute",
    top: "2px",
    right: "10px",
  },

  primaryContactWrapper: {
    display: "flex",
    gap: "8px",
    overflow: "hidden",
  },
  imgStyles: {
    width: "21px",
    height: "19px",
  },

  saveBtnStyles: {
    fontSize: "14px",
    height: "40px",
    backgroundColor: "primary.dark",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
    minWidth: "120px",
  },
  btnWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  dividerStylesV2: {
    margin: "28px 0 15px 0",
  },

  customFieldWrapper: {
    "& .MuiInputBase-root": {
      height: "40px",
    },
  },
  customEmailWrapper: {
    "& .MuiInputBase-root": {
      height: "40px",
    },
  },
  bulkInvite: {
    marginRight: "10px",
    borderRadius: "6px",
    border: "1px solid #056EE6",
    padding: "4px 8px",
    color: "primary.dark",
    fontSize: "12px",
    fontWeight: 600,
    '&:hover': {
      backgroundColor: "primary.light",
    },
  }
};

export default styles;
